:root {
    --primary-color: #00A88F;
    --secondary-color: #F5A623;
    --background-color: #F0F8F7;
    --text-color: #333333;
    --light-text-color: #666666;
    --white: #FFFFFF;
    --light-gray: #E0E0E0;
    --border-radius: 12px;
}
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color);
  }
  
  .App {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    width: calc(100% - 4rem);
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .calculator {
    display: flex;
    width: 100%;
    max-width: 1200px;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .dashboard {
    flex: 1;
    padding: 2rem;
    border-right: 1px solid var(--light-gray);
    background-color: white;
  }
  
  .activities-panel {
    flex: 1;
    padding: 1.5rem;
    background-color: var(--background-color);
    overflow-y: auto;
    max-height: 80vh;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: var(--light-text-color);
  }
  
  input[type="time"],
  input[type="text"],
  input[type="number"] {
    width: calc(96% - 0.75rem);
    padding: 0.75rem;
    border: 1px solid var(--light-gray);
    border-radius: var(--border-radius);
    font-size: 1rem;
  }
  
  .calculate-btn {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .calculate-btn:hover {
    background-color: #008F7A;
  }
  
  .loading,
  .error {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: var(--border-radius);
    text-align: center;
  }
  
  .loading {
    background-color: var(--light-gray);
    color: var(--text-color);
  }
  
  .error {
    background-color: #FFEBEE;
    color: #D32F2F;
  }
  
  .results {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
  }
  
  .timeline {
    margin-top: 2rem;
  }
  
  .add-activity-form {
    display: flex;
    margin-top: 1rem;
    gap: 0.5rem;
  }
  
  .add-activity-form input {
    flex-grow: 1;
  }
  
  .add-activity-button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .container {
        padding: 1rem;
        width: calc(100% - 2rem);
    }

    .calculator {
        flex-direction: column;
        max-height: none;
    }

    .dashboard,
    .activities-panel {
        flex: none;
        max-width: 100%;
        padding: 1rem;
    }

    .dashboard {
        order: 2;
        border-right: none;
        border-top: 1px solid var(--light-gray);
    }

    .activities-panel {
        order: 1;
        max-height: none;
        overflow-y: visible;
    }

    .form-group,
    .results,
    .timeline {
        margin-bottom: 1.5rem;
    }

    input[type="time"],
    input[type="text"],
    input[type="number"] {
        font-size: 16px;
        padding: 0.5rem;
        width: calc(100% - 1rem);
    }

    .add-activity-form {
        flex-direction: column;
    }

    .add-activity-form input,
    .add-activity-form button {
        width: calc(100% - 1rem);
        margin-bottom: 0.5rem;
    }

    .add-activity-button {
        align-self: flex-start;
    }
}

.add-activity-button {
    transition: transform 0.3s ease;
  }
  
  .add-activity-button:hover {
    transform: scale(1.1);
  }
  
  .add-activity-button.active {
    transform: rotate(45deg);
  }
  
  .add-activity-form-container {
    transition: max-height 0.3s ease, opacity 0.3s ease, margin-bottom 0.3s ease;
  }